import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import _ from 'lodash'
// import { Api, FC } from '../../Services'
// import { cap } from '../../State'
import React, { useState } from 'react'
import { FC } from '@Common/Services'
import { Button } from './Button'
import { Dialog } from 'primereact/dialog'
import { Flex } from './Flex'
import { Text } from './Text'
import { Spinner } from '.'
import { useWindowSize } from 'react-use'
import axios from 'axios'
import { API_BASE_URL } from '../Services/FeathersClient'
import state from '../../Apps/quotes/State'

const debounce = _.debounce((editor, id, onChange) => {
  const data = '<div class="ck-content">' + editor.getData() + '</div>'
  onChange({ [id]: data })
}, 600)

const Editor = ({ id, description, onChange, initialValue, setInitialValue, imageHandler, noDebounce, entityObj = false, disabled }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loadingAI, setLoadingAI] = useState(false)
  const [startValueBeforeAI, setStartValueBeforeAI] = useState('')
  const [newValueAfterAI, setNewValueAfterAI] = useState('')
  const { height: screenHeight } = useWindowSize()

  function MyCustomUploadAdapterPlugin (editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new MyUploadAdapter({ loader, entityObj })
    }
  }

  const customConfig = {
    toolbar: [
      'fontfamily',
      'fontsize',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'alignment',
      '|',
      'numberedList',
      'bulletedList',
      '|',
      'outdent',
      'indent',
      '|',
      'link',
      'blockquote',
      ...((imageHandler && entityObj) ? ['uploadImage'] : []),
      'insertTable',
      '|',
      'undo',
      'redo'
    ],
    extraPlugins: [MyCustomUploadAdapterPlugin]
  }

  const modifyText = async (action) => {
    setLoadingAI(true)
    // Use axios to avoid timeout
    const { data: { text } } = await axios.post(API_BASE_URL + '/ai', { action, text: description, type: 'modifyText' }, { headers: { Authorization: state.auth.state.jwt } })
    setLoadingAI(false)
    setNewValueAfterAI(text)
  }

  return (
    <div style={{ backgroundColor: 'white', width: '100%', borderRadius: 20, color: 'black', position: 'relative' }}>
      <Button
        icon='fa-stars' label='Funzioni AI' style={{ position: 'absolute', right: 5, top: 5 }} onClick={() => {
          setStartValueBeforeAI(description)
          setModalVisible(true)
        }}
      />
      <Dialog
        modal style={{ width: '98%', height: '98%', borderWidth: 0, borderRadius: 20 }}
        visible={modalVisible}
        closable
        onHide={() => setModalVisible(false)}
        content={({ hide }) =>
          <Flex fw fh as js style={{ backgroundColor: 'rgba(255,255,255,0.75)', padding: 20, borderRadius: 20 }}>
            <Flex fw ae>
              <Button icon='close' round onClick={hide} />
            </Flex>
            {loadingAI
              ? (
                <Flex fw fh>
                  <Spinner />
                </Flex>)
              : (
                <Flex fw>
                  <Flex fw row>
                    <Button label='Riassumi' icon='fa-list' onClick={() => modifyText('summarize')} />
                    <Button label='Correggi' icon='fa-badge-check' onClick={() => modifyText('correct')} style={{ marginLeft: 10, marginRight: 10 }} />
                    <Button label='Migliora' icon='fa-stars' onClick={() => modifyText('improve')} />
                  </Flex>
                  <Flex fw row>
                    <Flex style={{ width: '50%' }}>
                      <Text value='Testo iniziale' style={{ marginBottom: 20 }} size={22} bold />
                      <CKEditor
                        disabled editor={DecoupledEditor} data={startValueBeforeAI}
                        onReady={(editor) => {
                          editor.ui.view.editable.element.style.backgroundColor = 'rgba(255,255,255,0.8)'
                          editor.ui.view.editable.element.style.width = '100%'
                          editor.ui.view.editable.element.style.height = screenHeight * 0.7 + 'px'
                        }}
                      />
                    </Flex>
                    <Flex style={{ width: '50%' }}>
                      {newValueAfterAI &&
                        <>
                          <Text value='Testo modificato' style={{ marginBottom: 20 }} size={22} bold />
                          <div style={{ borderLeft: '1px solid black' }}>
                            <CKEditor
                              disabled editor={DecoupledEditor} data={newValueAfterAI}
                              onReady={(editor) => {
                                editor.ui.view.editable.element.style.backgroundColor = 'rgba(255,255,255,0.8)'
                                editor.ui.view.editable.element.style.width = '100%'
                                editor.ui.view.editable.element.style.height = screenHeight * 0.7 + 'px'
                              }}
                            />
                          </div>
                        </>}
                    </Flex>
                  </Flex>
                  <Flex fw row style={{ marginTop: 10 }}>
                    <Button
                      label='Conferma' icon='check' onClick={() => {
                        onChange({ [id]: newValueAfterAI })
                        setInitialValue({ ...initialValue, [id]: newValueAfterAI })
                        setModalVisible(false)
                      }}
                      disabled={!newValueAfterAI}
                      style={{ marginRight: 5, width: 150 }}
                    />
                    <Button label='Annulla' icon='close' onClick={hide} style={{ marginLeft: 5, width: 150 }} />
                  </Flex>
                </Flex>
                )}
          </Flex>}
      />
      <CKEditor
        disabled={disabled}
        onReady={editor => {
          editor?.ui?.getEditableElement()?.parentElement?.insertBefore(
            editor?.ui?.view?.toolbar?.element,
            editor?.ui?.getEditableElement()
          )
        }}
        onError={({ willEditorRestart }) => {
          if (willEditorRestart) {
            this.editor?.ui?.view?.toolbar?.element?.remove()
          }
        }}
        config={customConfig}
        onChange={(event, editor) => {
          if (noDebounce) {
            const data = '<div class="ck-content">' + editor.getData() + '</div>'
            onChange({ [id]: data })
          } else {
            debounce(editor, id, onChange)
          }
        }}
        editor={DecoupledEditor}
        data={initialValue}
      />
    </div>
  )
}

class MyUploadAdapter {
  constructor (props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props.loader
    this.entityObj = props.entityObj
    this.errorImage = { default: 'https://developers.google.com/maps/documentation/maps-static/images/error-image-generic.png?hl=it' }
    // URL where to send files.
  }

  // Starts the upload process.
  async upload () {
    try {
      const base64 = this.loader._reader._data
      return this.loader.file.then(({ name, type }) => this.uploadFile({ name, type }, base64))
    } catch (e) {
      console.log('ERR => ', e)
      return this.errorImage
    }
  }

  // Aborts the upload process.
  abort () {

  }

  async uploadFile (file, base64) {
    // const cap = { entity: '', entityId: '' }
    // const { entity, entityId } = cap // cap.entity.state
    try {
      const uri = base64
      const res = await FC.client
        .service('upload')
        .create({ uri }, {
          query: this.entityObj
        })
      const data = {
        file: file.name,
        format: file.type,
        from: 'BACKEND_APPTOUR'
      }
      const documentsRes = await FC.service('documents').patch(res.documentId, data)

      return { default: documentsRes.uri }
    } catch (e) {
      console.log('ERRORE => ', e)
      window.growl.show({
        severity: 'error',
        summary: 'Errore immagine inserita',
        detail: e.message === 'big' ? 'File troppo grande ' : 'Errore caricamento immagine'
      })
    }
    return this.errorImage
  }
}

export { Editor }
